<template>
    <div>
        test modal
    </div>
</template>

<script>
export default {
name: "Test"
}
</script>

<style scoped>
 div {
     width: 400px;
     height: 400px;
     background: white;
 }
</style>